import React, { useState, useEffect, useCallback, useContext } from 'react'

import { COURIERS } from '../../../constants/couriers'
import { TICKETS_STATUS, TICKET_STATES_INDEX, TICKET_STATUS_MANAGEMENT, TICKET_STATUSES } from '../../../constants/tickets'
import { TicketsContext } from '../../../contexts/TicketsContext'
import { getTickets } from '../../../helpers/requests/tickets'
import useFetch from '../../../hooks/useFetch'
import useLocalStorage from '../../../hooks/useLocalStorage'
import { sortArrayByKey } from '../../../utils/array'
import { isSubstring } from '../../../utils/strings'
import Pagination from '../../shared/Pagination'
import SearchBar from '../../shared/SearchBar'
import ActionsBar from '../../shared/Table/ActionsBar'
import TicketsSelection from '../SelectionBar/TicketsSelection'
import TicketsTable from '../TicketsTable'
import ExportTickets from '../ExportTickets'

import TicketsFilters from './TicketsFilters'

const AllTickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchBarInput, setSearchBarInput] = useLocalStorage('searchBarInput', '')
  const [selectedFilters, setSelectedFilters] = useLocalStorage('selectedFilters', [])
  const [page, setPage] = useLocalStorage('page', 1)
  const [pageSize, setPageSize] = useLocalStorage('pageSize', 10)
  const [searchResult, setSearchResult] = useState([])
  const [selectedType, setSelectedType] = useLocalStorage('selectedType', '')
  const [selectedCourier, setSelectedCourier] = useLocalStorage('selectedCourier', '')
  const [selectedReviewer, setSelectedReviewer] = useLocalStorage('selectedReviewer', '')
  const [selectedPriority, setSelectedPriority] = useLocalStorage('selectedPriority', '')
  const [selectedPendingFilter, setSelectedPendingFilter] = useLocalStorage('selectedPendingFilter', true)
  const [selectedTickets, setSelectedTickets] = useState([])

  const { setAllFilteredTickets, setAllTickets } = useContext(TicketsContext)

  const setTicketsData = useCallback((data) => {
    setAllTickets(data)
    const flattenTickets = data.map((ticket) => {
      const item = { ...ticket, ...ticket.package }
      delete item.package
      return item
    })
    flattenTickets.sort((ticketA, ticketB) => ticketB.id - ticketA.id)

    setTickets(flattenTickets)
    setSearchResult(flattenTickets)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { isLoading, error } = useFetch(getTickets, setTicketsData)

  useEffect(() => {
    if (searchBarInput !== '') {
      const filteredTickets = tickets.filter(
        (ticket) =>
          isSubstring(ticket.id.toString(), searchBarInput) ||
          (ticket.pinflagId && isSubstring(ticket.pinflagId, searchBarInput)) ||
          (ticket.courier && isSubstring(ticket.courier, searchBarInput)) ||
          (ticket.orderId && isSubstring(ticket.orderId, searchBarInput)) ||
          (ticket.customerName && isSubstring(ticket.customerName, searchBarInput)) ||
          (ticket.holdingName && isSubstring(ticket.holdingName, searchBarInput)) ||
          (ticket.trackingNumber && isSubstring(ticket.trackingNumber, searchBarInput))
      )
      setSearchResult(filteredTickets)
    } else {
      setSearchResult(tickets)
    }
  }, [searchBarInput, tickets])

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentTickets = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentTickets)
    const sortedTickets = sortArrayByKey(tickets, sortValues, order)

    setTickets(sortedTickets)
  }

  const isPendingTicket = (ticket) => (
    ticket.status === TICKETS_STATUS[0].name || (ticket.lastMessageSender === 'ecommerce' && !TICKET_STATUSES.CLOSED.includes(ticket.status))
  )

  const isNotClosedTicket = (ticket) => (isPendingTicket(ticket) ||
  !TICKET_STATUSES.CLOSED.includes(ticket.status))

  const stateFilteredTickets = searchResult
    .filter((ticket) =>
      selectedFilters.length > 0
        ? selectedFilters.includes(TICKET_STATES_INDEX[ticket.status])
        : true
    )
    .filter((ticket) => (selectedPendingFilter ? isPendingTicket(ticket) :
    isNotClosedTicket(ticket)))
    .filter((ticket) => (selectedType ? ticket.type === selectedType : true))
    .filter((ticket) => {
      if (selectedCourier === 'Otros Couriers') {
        return ticket.courier && !COURIERS.includes(ticket.courier)
      }
      if (selectedCourier === 'Sin Courier') {
        return !ticket.courier
      }
      return selectedCourier ? ticket.courier === selectedCourier : true
    })
    .filter((ticket) => (selectedPriority ? ticket.priority === selectedPriority : true))
    .filter((ticket) => (selectedReviewer ? ticket.reviewerId === +selectedReviewer : true))

  useEffect(() => {
    setAllFilteredTickets((prev) => {
      const filteredIds = stateFilteredTickets.map(ticket => ticket.id)
      if (JSON.stringify(prev) !== JSON.stringify(filteredIds)) {
        return filteredIds
      }
      return prev
    })
  }, [stateFilteredTickets, setAllFilteredTickets])

  const currentTickets = stateFilteredTickets.slice(pageSize * (page - 1), pageSize * page)

  useEffect(() => {
    if (selectedFilters.length === 0) setSelectedPendingFilter(true)
    else setSelectedPendingFilter(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters])

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className='flex w-full justify-end px-8'>
        <div className="mx-2 hidden w-1/6 self-center overflow-hidden md:block">
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        </div>
      </div>
      <ActionsBar
        statusFilters={TICKET_STATUS_MANAGEMENT}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        rightChildren={
          <div className="flex gap-4">
            <TicketsFilters
              tickets={tickets}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedCourier={selectedCourier}
              setSelectedCourier={setSelectedCourier}
              selectedPriority={selectedPriority}
              setSelectedPriority={setSelectedPriority}
              selectedReviewer={selectedReviewer}
              setSelectedReviewer={setSelectedReviewer}
            />
            {tickets.length > 0 && <ExportTickets tickets={tickets} />}
          </div>
        }

        customFirstFilter={{
          name: 'Pendientes',
          isActive: selectedPendingFilter,
          handleFilter: () => setSelectedPendingFilter(!selectedPendingFilter)
        }}
      />
      <TicketsTable
        tickets={currentTickets}
        selectedTickets={selectedTickets}
        setSelectedTickets={setSelectedTickets}
        handleSort={handleSort}
        isLoading={isLoading}
        error={error}
      />
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredTickets.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredTickets.length}
          />
        )}
        {selectedTickets.length > 0 && (
          <div className="absolute bottom-28 left-10">
            <TicketsSelection
              selectedTickets={selectedTickets}
              handleCloseBar={() => {
                setSelectedTickets([])
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AllTickets
