import React, { useState, useEffect } from 'react'

import { PRIORITY_OPTIONS, TICKET_STATUSES_REVIEW_OPTIONS, TICKET_OPTIONS_CLIENT, OTHER_TYPE_OPTIONS } from '../../../constants/tickets'
import { getCompaniesByHoldingId } from '../../../helpers/requests/companies'
import { getSale } from '../../../helpers/requests/sales'
import { editTicketReview, getTicketByPinflagId } from '../../../helpers/requests/tickets'
import { getSuperUsers } from '../../../helpers/requests/users'
import useFetch from '../../../hooks/useFetch'
import Button from '../../shared/Button'
import Modal from '../../shared/Modal/Modal'

const EditTicketReview = ({ ticket, onTicketUpdated }) => {
  const [selectedType, setSelectedType] = useState(ticket.type)
  const [selectedPriority, setSelectedPriority] = useState(ticket.priority)
  const [selectedStatus, setSelectedStatus] = useState(
    Object.keys(TICKET_STATUSES_REVIEW_OPTIONS).includes(ticket.status)
      ? ticket.status
      : '')
  const [selectedAssignee, setSelectedAssignee] = useState(ticket.reviewerId)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [usersData, setUsersData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [packageId, setPackageId] = useState('')
  const [modalError, setModalError] = useState('')
  const [companies, setCompanies] = useState({})
  const [selectedCompanyId, setSelectedCompanyId] = useState(null)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)

  useEffect(() => {
    const fetchCompanies = async () => {
      if (showModal) {
        setIsLoadingCompanies(true)
        try {
          const companiesData = await getCompaniesByHoldingId(ticket.holdingId)
          setCompanies(companiesData)
        } catch (error) {
          setModalError('Error al cargar las empresas')
        } finally {
          setIsLoadingCompanies(false)
        }
      }
    }

    fetchCompanies()
  }, [showModal, ticket.holdingId])

  const REVIEWERS_OPTIONS = usersData.reduce((acc, user) => {
    acc[user.id] = user.name || user.email
    return acc
  }, {})

  const { isLoading: isUsersLoading, error: usersError } = useFetch(getSuperUsers, setUsersData)

  const getChangeIfDifferent = (newValue, originalValue) =>
    newValue !== originalValue ? newValue || undefined : undefined

  const getChanges = (additionalData = {}) => ({
    priority: getChangeIfDifferent(selectedPriority, ticket.priority),
    status: getChangeIfDifferent(selectedStatus, ticket.status),
    type: getChangeIfDifferent(selectedType, ticket.type),
    reviewerId: getChangeIfDifferent(selectedAssignee, ticket.reviewerId),
    ...additionalData
  })

  const modifyTicketReview = async (additionalData = {}) => {
    setIsLoading(true)
    try {
      await editTicketReview(ticket.id, getChanges(additionalData))
      onTicketUpdated()
      setShowModal(false)
    } catch (err) {
      const errorMsg = err.response?.data?.message || 'Error al procesar la solicitud'
      if (showModal) {
        setModalError(errorMsg)
      } else {
        setErrorMessage(errorMsg)
      }
    }
    setIsLoading(false)
  }

  const handleSaveClick = async () => {
    if (selectedType === '') return

    const isCurrentTypeOther = Object.keys(OTHER_TYPE_OPTIONS).includes(ticket.type)
    const isNewTypeNotOther = selectedType &&
    !Object.keys(OTHER_TYPE_OPTIONS).includes(selectedType)

    if (isCurrentTypeOther && isNewTypeNotOther) {
      setShowModal(true)
    } else if (!isCurrentTypeOther && !isNewTypeNotOther) {
      modifyTicketReview({ pinflagId: null })
    } else {
      await modifyTicketReview()
    }
  }

  const validateModalInputs = () => {
    if (!packageId.trim()) {
      setModalError('Por favor ingresa un código')
      return false
    }

    if (!selectedCompanyId) {
      setModalError('Por favor selecciona una empresa')
      return false
    }

    return true
  }

  const checkExistingTicket = async (pinflagId) => {
    const existingTicket = await getTicketByPinflagId(pinflagId)
    if (!existingTicket.error) {
      setModalError(`Ya existe un ticket asociado a esta venta (Ticket #${existingTicket.id})`)
      setIsLoading(false)
      return true
    }
    return false
  }

  const getSaleData = async () => {
    const saleData = await getSale(packageId, { params: { companyId: selectedCompanyId }})
    if (!saleData.pinflagId) {
      setModalError('No se encontró la orden de venta')
      setIsLoading(false)
      return null
    }
    return saleData
  }

  const handleModalSubmit = async () => {
    if (!validateModalInputs()) return

    setIsLoading(true)
    try {
      const saleData = await getSaleData()
      if (!saleData) return

      const hasExistingTicket = await checkExistingTicket(saleData.pinflagId)
      if (hasExistingTicket) return

      await modifyTicketReview({ pinflagId: saleData.pinflagId })
    } catch (err) {
      setModalError(err.response?.data?.message || err.response?.data?.es || 'Error al procesar la solicitud')
      setIsLoading(false)
    }
  }

  const ticketSelect = (name, selectedValue, setSelectedValue, options) => (
    <div className='flex w-1/5 flex-col gap-1'>
      <div className='text-xs'>{name}</div>
      <select
      className="rounded-lg border p-2"
      value={selectedValue}
      onChange={(e) => setSelectedValue(e.target.value)}
      >
        <option value="">{name}</option>
        {Object.entries(options).map(([value, label]) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </select>
    </div>
  )

  if (errorMessage || usersError) {
    return <div className='text-red'>{errorMessage || usersError}</div>
  }

  if (isUsersLoading) {
    return <div>Cargando...</div>
  }

  return (
    <>
      <div className="flex w-full gap-3 text-sm">
        {ticketSelect('Tipo de Ticket', selectedType, setSelectedType, TICKET_OPTIONS_CLIENT)}
        {ticketSelect('Prioridad', selectedPriority, setSelectedPriority, PRIORITY_OPTIONS)}
        {ticketSelect('Estado', selectedStatus, setSelectedStatus, TICKET_STATUSES_REVIEW_OPTIONS)}
        {ticketSelect('Asignado a', selectedAssignee, setSelectedAssignee, REVIEWERS_OPTIONS)}
        <button type="button" className=" h-3/5 self-end rounded-lg border p-2 text-pinflag" onClick={handleSaveClick}>{isLoading ? 'Guardando...' : 'Guardar'}</button>
      </div>
      <Modal
        show={showModal}
        handleClose={() => {
          setShowModal(false)
          setSelectedCompanyId(null)
          setModalError('')
          setPackageId('')
        }}
      >
        <div className="flex flex-col gap-4 p-3 ">
          <div className="font-medium">Ingresa el código pinflag o la orden de venta para continuar</div>
          <input
            className="w-full rounded border p-1"
            value={packageId}
            onChange={(e) => setPackageId(e.target.value)}
          />
          <select
            className="w-full rounded border p-1"
            onChange={(event) => setSelectedCompanyId(event.target.value)}
            value={selectedCompanyId || ''}
            disabled={isLoadingCompanies}
          >
            <option value="" hidden>
              {isLoadingCompanies ? 'Cargando empresas...' : 'Empresa'}
            </option>
            {companies.length > 0 && companies?.map((company) => (
              <option key={company.companyId} value={company.companyId}>
                {company.name}
              </option>
            ))}
          </select>
          {modalError && <p className="mb-4 text-sm">{modalError}</p>}
          <div className="flex justify-end gap-2">
            <Button
              onClick={() => setShowModal(false)}
              border="border"
              color="bg-white"
              textColor="text-black"
            >
              Cancelar
            </Button>
            <Button
              onClick={handleModalSubmit}
              color="bg-pinflag"
              inactive={isLoading || isLoadingCompanies}
            >
              {isLoading ? 'Procesando...' : 'Continuar'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditTicketReview
